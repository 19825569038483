<template>
  <GooSkeleton v-if="loading" width="100%" height="50" />
  <button v-else type="button" class="goo-button is-large is-full-width" @click="onClick">
    <img class="mr-2" src="@/assets/auth/google-stamp.svg" alt="" width="24" height="24">
    <span>
      <slot>Sign in with Google</slot>
    </span>
  </button>
</template>

<script>
  import google from "@/lib/auth/google";

  export default {
    name: "GoogleSignInButton",
    data () {
      return {
        loading: true
      };
    },
    mounted () {
      google.init().then(() => {
        this.loading = false;
      }).catch((error) => {
        console.log(error);
      });
    },
    methods: {
      async onClick () {
        try {
          await google.signIn();
          this.$emit("authenticated");
        } catch (e) {
          console.log(e);
        }
      }
    }
  };
</script>

<style lang="scss">

</style>
