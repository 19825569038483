<template>
  <div class="itza-layout">
    <Nav :product-name="product.name" :link="product.url" :partnership="partnership" />

    <div class="body-5 pt-14 pt-ms-16">
      <div v-if="!isLoading" class="has-text-centered px-5">
        <p>Please fill out your name, password, and email to watch the Whale Guardian.</p>
        <p>After finishing, you'll be able to help Gooroo decide where to send our donation.</p>
      </div>
      <div class="itza-layout__grid">
        <div class="px-md-5 itza-layout__left-column">
          <slot />
        </div>
        <div class="itza-layout__right-column itza-layout__right-column__card">
          <ItzaCourseCard class="itza-layout__course-card" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Nav from "@/components/Settings/components/Nav";
  import ProductAwareMixin from "@/mixins/ProductAwareMixin";
  import ItzaCourseCard from "./ItzaCourseCard";

  export default {
    name: "ItzaLayout",
    components: { ItzaCourseCard, Nav },
    mixins: [ProductAwareMixin],
    props: {
      isLoading: {
        type: Boolean,
        required: true
      },
      partnership: {
        type: String,
        required: false,
        default: null
      }
    }
  };
</script>

<style lang="scss">
@import "~@gooroo-life/goo-ui/src/scss/utilities/utilities";

.itza-layout {
  &__grid {
    max-width: 720px;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0 1rem;

    @include respond-large {
      max-width: 975px;
      display: grid;
      grid-template-columns: 6fr 6fr;
      grid-column-gap: 0;
    }
  }

  &__left-column {
    order: 1;
  }

  &__right-column {
    display: none;

    @include respond-large {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__card {
      justify-content: start;
      margin-top: 2.5rem;
    }
  }

  &__image {
    width: 100%;
    height: auto;
    -webkit-user-drag: none;
    user-select: none;
    align-items: center;
  }

  .goo-header-nav {
    justify-content: space-between;
  }

  .goo-header-main {
    max-width: 20rem;
  }

}
</style>
