<template>
  <AuthLayout :image="image" reversed>
    <h2 class="heading-2 mb-5 has-text-centered-large-down">
      Welcome back!
    </h2>

    <GoogleSignInButton @authenticated="handleIntent">
      Log in with Google
    </GoogleSignInButton>

    <div class="goo-divider has-text my-3">
      <span class="goo-divider-text">or, log in with email</span>
    </div>

    <form @submit.prevent="onSubmit">
      <GooTextInput
        v-model="email"
        class="mb-3"
        label="Email Address"
        type="email"
        name="email"
        :error="errorForField('email')"
        required
        autofocus
      />

      <GooTextInput
        ref="passwordField"
        v-model="password"
        class="mb-3"
        label="Password"
        type="password"
        name="password"
        :error="errorForField('password')"
        required
      />

      <p class="mb-3 has-text-right">
        <RouterLink :to="{ name: 'auth.forgot-password', query: {product: $route.query.product } }" class="goo-link is-secondary">
          Forgot Password?
        </RouterLink>
      </p>

      <GooSubmit type="submit" class="goo-button is-primary is-full-width" :loading-text="false" :loading="loading">
        Log In
      </GooSubmit>
    </form>

    <hr class="goo-divider mt-3 mb-2">

    <p class="has-text-centered">
      Don't have an account?
      <RouterLink :to="signUpURL" class="goo-link is-secondary">
        Sign up
      </RouterLink>
    </p>
  </AuthLayout>
</template>

<script>
  import stash from "@/lib/stash";

  import APIClient from "@/lib/api/APIClient";
  import AuthLayout from "@/components/Auth/AuthLayout";
  import ReadingImage from "@/assets/auth/signup-reading.jpg";
  import GoogleSignInButton from "@/components/Auth/GoogleSignInButton";
  import GooTextInput from "@/goo2/components/text-input/GooTextInput";
  import FormErrorsMixin from "@/mixins/FormErrorsMixin";
  import SafeRedirectMixin from "@/mixins/SafeRedirectMixin";
  import ProductAwareMixin from "@/mixins/ProductAwareMixin";

  export default {
    name: "SignupScreen",
    components: { GooTextInput, GoogleSignInButton, AuthLayout },
    mixins: [FormErrorsMixin, SafeRedirectMixin, ProductAwareMixin],
    props: {
      user: {
        type: Object,
        default: null
      }
    },
    data () {
      return {
        email: "",
        password: "",
        loading: false
      };
    },
    computed: {
      image () {
        return ReadingImage;
      },
      signUpURL () {
        return {
          name: "auth.signup",
          query: this.$route.query
        };
      }
    },
    mounted () {
      const email = stash.get("email");
      if (email) {
        this.email = email;
        this.$refs.passwordField.focus();
      }

      if (this.user !== null) {
        this.handleIntent();
      }
    },
    methods: {
      onSubmit () {
        this.loading = true;
        this.clearErrors();

        const api = new APIClient();
        api.login(this.email, this.password).then(() => {
          this.handleIntent();
        }).catch((error) => {
          this.errors = error.errors;
        }).finally(() => {
          this.loading = false;
        });
      }
    }
  };
</script>

<style lang="scss">

</style>
