<template>
  <PasswordResetLayout>
    <form @submit.prevent="onSubmit">
      <div v-if="sent" class="has-text-centered">
        <div class="mb-3">
          <GooIcon name="circle-check-outline" type="is-primary" :width="60" :height="60" />
        </div>

        <h2 class="heading-2 mb-3">
          Link sent!
        </h2>

        <p>
          We sent an email to {{ email }} with instructions to reset your password.
          If you haven’t received an email from us, please check your spam or junk folder.
        </p>
      </div>
      <template v-else>
        <h2 class="heading-2">
          Forgot password
        </h2>

        <p>
          Please enter the email address associated with your account. We’ll send you a link to reset your password.
        </p>

        <GooTextInput
          v-model="email"
          :error="errorForField('email')"
          label="Email Address"
          type="email"
          required
          autofocus
        />

        <p>
          <GooSubmit class="goo-button is-primary is-full-width" :loading="loading" :loading-text="false">
            Send Link
          </GooSubmit>
        </p>
      </template>
    </form>
  </PasswordResetLayout>
</template>

<script>
  import APIClient from "@/lib/api/APIClient";
  import PasswordResetLayout from "./PasswordResetLayout";
  import FormErrorsMixin from "@/mixins/FormErrorsMixin";
  import GooTextInput from "@/goo2/components/text-input/GooTextInput";

  export default {
    name: "ForgotPasswordScreen",
    components: { GooTextInput, PasswordResetLayout },
    mixins: [FormErrorsMixin],
    data () {
      return {
        email: "",
        sent: false,
        loading: false
      };
    },
    methods: {
      onSubmit () {
        this.loading = true;
        this.clearErrors();

        const api = new APIClient();
        api.createPasswordResetToken(this.email).then(() => {
          this.sent = true;
        }).catch((error) => {
          this.errors = error.errors;
        }).finally(() => {
          this.loading = false;
        });
      }
    }
  };
</script>

<style lang="scss">

</style>
