<template>
  <form @submit.prevent="onSubmit">
    <h2 class="heading-2">
      Reset password
    </h2>

    <p>Passwords need to be a minimum of 8 characters.</p>

    <GooTextInput
      v-model="password"
      class="mb-3"
      label="New Password"
      type="password"
      name="password"
      :error="errorForField('password')"
      minlength="8"
      required
    />

    <GooTextInput
      v-model="passwordConfirmation"
      class="mb-3"
      label="Confirm password"
      type="password"
      name="password_confirmation"
      :error="errorForField('password_confirmation')"
      required
    />

    <div class="goo-button-group mb-3">
      <GooSubmit class="goo-button is-primary is-full-width" type="submit" :loading="updating" :loading-text="false">
        Save and Continue
      </GooSubmit>
    </div>

    <p class="has-text-centered">
      Need help?
      <a href="https://help.gooroo.com/hc/en-us/requests/new" rel="noopener" target="_blank" class="goo-link is-secondary">Contact us</a>
    </p>
  </form>
</template>

<script>
  import APIClient from "@/lib/api/APIClient";
  import APIClientError from "@/lib/api/APIClientError";
  import GooTextInput from "@/goo2/components/text-input/GooTextInput";
  import FormErrorsMixin from "@/mixins/FormErrorsMixin";

  export default {
    name: "ResetPasswordForm",
    components: { GooTextInput },
    mixins: [FormErrorsMixin],
    props: {
      token: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        password: "",
        passwordConfirmation: "",
        updating: false
      };
    },
    methods: {
      async onSubmit () {
        if (this.password !== this.passwordConfirmation) {
          return this.addError("password_confirmation", "Passwords don't match");
        }

        this.updating = true;

        try {
          const api = new APIClient();
          await api.resetPassword(this.password, this.token);
          await this.$router.push({ name: "auth.login" });
        } catch (reason) {
          if (reason instanceof APIClientError) {
            this.errors = reason.errors;
          }

          throw reason;
        } finally {
          this.updating = false;
        }
      }
    }
  };
</script>

<style scoped>

</style>
