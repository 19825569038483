import APIClient from "@/lib/api/APIClient";

const GOOGLE_LIBRARY_SCRIPT_ID = "google-api-client";

const GOOGLE_CLIENT_ID = "366663996952-7327a8hsoddmr09lnbq1t905g6gujm9g.apps.googleusercontent.com";

let auth2;

function embedGoogleAPIClient () {
  if (document.getElementById(GOOGLE_LIBRARY_SCRIPT_ID) === null) {
    const script = document.createElement("script");
    script.id = GOOGLE_LIBRARY_SCRIPT_ID;
    script.src = "https://apis.google.com/js/platform.js?onload=onGoogleClientLoaded";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }
}

function loadAuth2 () {
  if (auth2) {
    return Promise.resolve(auth2);
  }

  return new Promise((resolve, reject) => {
    window.onGoogleClientLoaded = function () {
      window.gapi.load("auth2", function () {
        try {
          auth2 = window.gapi.auth2.init({
            client_id: GOOGLE_CLIENT_ID
          });

          resolve(auth2);
        } catch (e) {
          reject(e);
        }
      });
    };

    embedGoogleAPIClient();
  });
}

async function init () {
  return loadAuth2();
}

async function signIn () {
  const googleUser = await auth2.signIn();

  // noinspection JSUnresolvedFunction
  const authResponse = googleUser.getAuthResponse();

  const api = new APIClient();
  // noinspection JSUnresolvedVariable
  await api.loginWithGoogle(authResponse.id_token);
}

export default {
  init,
  signIn
};
