import gql from "graphql-tag";

export const AUTHORIZE_USER_WITH_ITZA = gql`
  mutation authorizeUserWithItza($input: AuthorizeUserWithItzaInput!) {
    input: authorizeUserWithItza(input: $input) {
      ... on AuthorizeUserWithItzaSuccess {
        isAdult
        userId
        displayName
        email
        refreshToken
        loggedIn
      }
      ... on AuthorizeUserWithItzaFailure {
        errorMessage
      }
    }
  }
`;
