<template>
  <div class="password-reset-layout">
    <Nav :product-name="product.name" :link="product.url" />
    <div class="password-reset-layout__wrapper pt-11 pt-md-16">
      <div class="password-reset-layout__content mt-lg-5 px-4 py-5 px-md-9 py-md-5">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
  import Nav from "@/components/Settings/components/Nav";
  import ProductAwareMixin from "@/mixins/ProductAwareMixin";

  export default {
    name: "PasswordResetLayout",
    components: { Nav },
    mixins: [ProductAwareMixin]
  };
</script>

<style lang="scss">
  .password-reset-layout {
    background: #fafafa;
    min-height: 100vh;

    &__wrapper {
      padding: 0 1rem;
    }

    &__content{
      background: #ffffff;
      margin: 0 auto;
      max-width: 500px;
      border: 1px solid #ebebeb;
      border-radius: 4px;
    }
  }
</style>
