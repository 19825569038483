<template>
  <div class="auth-layout">
    <Nav :product-name="product.name" :link="product.url" />

    <div class="auth-layout__grid pt-11 pt-md-16">
      <div class="auth-layout__left-column p-md-5">
        <slot />
      </div>
      <div class="auth-layout__right-column">
        <img class="auth-layout__image" :src="image" width="950" height="720" alt="">
      </div>
    </div>
  </div>
</template>

<script>
  import Nav from "@/components/Settings/components/Nav";
  import ProductAwareMixin from "@/mixins/ProductAwareMixin";

  export default {
    name: "AuthLayout",
    components: { Nav },
    mixins: [ProductAwareMixin],
    props: {
      image: {
        type: String,
        required: true
      }
    }
  };
</script>

<style lang="scss">
  @import "~@gooroo-life/goo-ui/src/scss/utilities/utilities";

  .auth-layout {
    &__grid {
      max-width: 720px;
      box-sizing: border-box;
      margin: 0 auto;
      padding: 0 1rem;

      @include respond-large {
        max-width: 1000px;
        display: grid;
        grid-template-columns: 6fr 6fr;
        grid-column-gap: 0;
      }
    }

    &__right-column {
      display: none;

      @include respond-large {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    &__image {
      width: 100%;
      height: auto;
      -webkit-user-drag: none;
      user-select: none;
    }
  }
</style>
