
export default class APIClientError extends Error {
  /**
   * @param reason {array|{message: string}|{error: string}}
   */
  constructor (reason = []) {
    if (typeof reason === "object") {
      super(reason.message || reason.error || "An error has occurred");
    } else {
      super("An error has occurred");
    }

    this.errors = Array.isArray(reason) ? reason : [];
  }
}
