<template>
  <div class="itza-course-card">
    <div v-if="course" class="itza-course-card__image">
      <img
        :src="course.image"
        :alt="course.name"
        width="480"
        height="270"
        loading="lazy"
        aria-hidden="true"
      >
    </div>
    <div v-if="course" class="itza-course-card__content">
      <div class="itza-course-card__detail body-3">
        <p class="is-gray-extra-dark-text-color">
          {{ course.instructor.firstName }} {{ course.instructor.lastName }}
        </p>
        <p class="is-gray-extra-dark-text-color">
          {{ course.duration | courseDuration }}
        </p>
      </div>
      <h3 class="itza-course-card__title heading-4 is-marginless">
        {{ course.name }}
      </h3>
    </div>
  </div>
</template>

<script>
  import { courseDuration } from "@/lib/course-utils";
  import { GET_COURSE } from "@/components/queries";

  export default {
    name: "ItzaCourseCard",
    filters: {
      courseDuration
    },
    data () {
      return {
        course: null,
        courseId: null
      };
    },
    apollo: {
      course: {
        query: GET_COURSE,
        client: "courses",
        variables () {
          return {
            id: this.courseId
          };
        },
        skip () {
          return !this.courseId;
        }
      }
    },
    created () {
      if (this.$route.query.next) {
        const url = this.$route.query.next;
        this.courseId = url.split("/").pop();
      } else {
        window.location.href = "https://courses.gooroo.com/courses/whale-guardian/1123";
      }
    }
  };
</script>

<style lang="scss">
  @import "src/assets/sass/params.scss";

  .itza-course-card {
    width: 100%;
    display: block;
    box-sizing: border-box;
    border: 1px solid $card-border-color-light;
    border-radius: $card-radius;

  &__image {
    position: relative;
    line-height: 0;
    border-radius: 3px 3px 0 0;
    overflow: hidden;

    img {
      display: block;
      line-height: 1rem;
      width: 100%;
      height: auto;
    }
  }

  &__content {
    padding: 0.5rem 1rem 1rem 1rem;
    position: relative;
  }

  &__detail {
    display: flex;
    justify-content: space-between;
  }
}
</style>
