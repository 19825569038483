<template>
  <ItzaLayout :partnership="partnership" :is-loading="isLoading">
    <div v-if="isLoading">
      <GooSpinner class="itza-layout__spinner" />
      <p class="has-text-centered mt-2">
        We are redirecting you to Gooroo clubs
      </p>
    </div>
    <div v-else>
      <form class="mt-5" @submit.prevent="signup">
        <div v-if="getUser && !getUser.itzaUserId">
          <button type="button" class="goo-button is-large is-full-width is-outlined is-primary" @click="updateUserWithItzaInfo">
            <span>
              <slot>Continue as {{ getUser.firstName }}</slot>
            </span>
          </button>

          <div class="goo-divider has-text my-3">
            <span class="goo-divider-text">Not {{ getUser.firstName }}?</span>
          </div>
        </div>

        <div v-if="registeredUser && !getUser" class="mb-4 body-2 has-text-centered">
          There is already an account with your Itza email address. Please
          <RouterLink :to="loginURL" class="goo-link is-secondary">Log in</RouterLink>
          instead.
        </div>

        <GooTextInput
          ref="firstNameField"
          v-model="firstName"
          name="first_name"
          :error="errorForField('first_name')"
          :disabled="registeredUser"
          class="mb-3"
          label="First Name"
          placeholder="Who is doing the challenge?"
          required
        />
        <GooTextInput
          v-model="lastName"
          name="last_name"
          :error="errorForField('last_name')"
          :disabled="registeredUser"
          class="mb-3"
          label="Last Name"
          placeholder="Your family name"
          required
        />

        <GooTextInput
          v-model="password"
          name="password"
          :error="errorForField('password')"
          class="mb-3"
          label="Create a Password"
          type="password"
          placeholder="Minimum 8 characters"
          :disabled="registeredUser"
          required
        />

        <div class="goo-checkbox">
          <input id="checkbox" v-model="isAgree" type="checkbox" :disabled="registeredUser">
          <label v-if="result" for="checkbox" class="heading-5">Use my {{ result.isAdult ? "": "guardian’s" }} email address from Itza account to create my Gooroo account.</label>
        </div>

        <GooSubmit class="goo-button is-primary is-full-width my-4" :disabled="!isAgree || registeredUser" :loading-text="false" :loading="submitting">
          Sign up and Watch
        </GooSubmit>

        <p class="body-3">
          By clicking the button, you agree to our
          <a class="goo-link is-alternate" href="https://www.gooroo.com/terms-of-use" target="_blank">Terms of Use</a> and
          <a class="goo-link is-alternate" href="https://www.gooroo.com/privacy-policy" target="_blank">Privacy Policy.</a>
        </p>
      </form>

      <hr class="goo-divider mt-3 mb-2">

      <p class="has-text-centered">
        Already have an account?
        <RouterLink :to="loginURL" class="goo-link is-secondary">
          Log in
        </RouterLink>
      </p>
    </div>
  </ItzaLayout>
</template>

<script>
  import { AUTHORIZE_USER_WITH_ITZA } from "@/components/Auth/mutation";
  import GooTextInput from "@/goo2/components/text-input/GooTextInput";
  import FormErrorsMixin from "@/mixins/FormErrorsMixin";
  import SafeRedirectMixin from "@/mixins/SafeRedirectMixin";
  import APIClient from "@/lib/api/APIClient";
  import APIClientError from "@/lib/api/APIClientError";
  import auth from "@/auth";
  import ItzaLayout from "./ItzaLayout";

  const api = new APIClient();

  export default {
    name: "ItzaRedirect",
    components: { ItzaLayout, GooTextInput },
    mixins: [FormErrorsMixin, SafeRedirectMixin],
    data () {
      return {
        result: null,
        submitting: false,
        isAgree: true,
        firstName: null,
        lastName: null,
        password: null,
        authUserWithItza: true,
        partnership: "itza",
        registeredUser: false
      };
    },
    computed: {
      loginURL () {
        return {
          name: "auth.login",
          query: this.$route.query
        };
      },
      getUser () {
        return auth.user;
      },
      isLoading () {
        return !this.result;
      }
    },
    async created () {
      if (this.$route.query.r) {
        await this.$apollo.mutate({
          mutation: AUTHORIZE_USER_WITH_ITZA,
          client: "courses",
          variables: {
            input: {
              scrambledRefreshToken: this.$route.query.r
            }
          }
        }).then((userData) => {
          if (userData.data.input.loggedIn) {
            this.handleIntent();
          } else {
            this.result = userData.data.input;
          }
        }).catch((error) => {
          console.log(error);
        });
        if (this.result.isAdult) {
          this.checkRegistrationStatus();
        }
      } else {
        window.location.href = process.env.VUE_APP_GOOROO_COURSES_URL;
      }
    },
    methods: {
      async updateUserWithItzaInfo () {
        await api.updateUser({
          itzaUserId: this.result.userId,
          itzaRefreshToken: this.result.refreshToken
        }).catch((error) => {
          this.errors = error.errors;
        });
        this.handleIntent();
      },
      async signup () {
        this.submitting = true;
        this.clearErrors();

        try {
          await api.signup({
            email: this.result.email,
            firstName: this.firstName,
            lastName: this.lastName,
            password: this.password,
            itzaUserId: this.result.userId,
            itzaRefreshToken: this.result.refreshToken,
            isAdult: this.result.isAdult
          });

          this.handleIntent();
        } catch (reason) {
          if (reason instanceof APIClientError) {
            this.errors = reason.errors;
          }
        } finally {
          this.submitting = false;
        }
      },
      async checkRegistrationStatus () {
        this.submitting = true;
        this.clearErrors();
        try {
          const { status } = await api.checkRegistrationStatus(this.result.email);
          if (status && status !== "NOT_REGISTERED") {
            this.registeredUser = true;
          }
        } catch (reason) {
          console.log(reason.message);
        } finally {
          this.submitting = false;
        }
      }
    }
  };
</script>

<style lang="scss">
  @import "~@gooroo-life/goo-ui/src/scss/utilities/utilities";
  input:disabled {
    background-color: $gray-light;
  }

  .itza-layout {
    &__spinner {
      display:table;
      margin: 0 auto;
      padding-top: 10rem;
    }
  }

</style>
