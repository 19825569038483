<template>
  <PasswordResetLayout>
    <ResetPasswordForm v-if="status === 'valid'" :token="token" />
    <div v-else-if="status === 'expired'" class="has-text-centered">
      <div class="has-text-centered mb-3">
        <GooIcon name="error-circle-outline" type="is-error" :width="60" :height="60" />
      </div>

      <h2 class="heading-2 mb-3">
        The link has expired
      </h2>
      <p class="has-text-centered m-0">
        Please
        <RouterLink class="goo-link" :to="{ name: 'auth.forgot-password', query: { product: product.id } }">
          request a new link
        </RouterLink>
        in order to reset your password.
      </p>
    </div>
    <div v-else class="has-text-centered ">
      <GooSkeleton class="mb-2" />
      <GooBodyTextSkeleton />
    </div>
  </PasswordResetLayout>
</template>

<script>
  import APIClient from "@/lib/api/APIClient";
  import ProductAwareMixin from "@/mixins/ProductAwareMixin";
  import ResetPasswordForm from "@/components/PasswordReset/ResetPasswordForm";
  import PasswordResetLayout from "@/components/PasswordReset/PasswordResetLayout";

  export default {
    name: "ResetPasswordScreen",
    components: { ResetPasswordForm, PasswordResetLayout },
    mixins: [ProductAwareMixin],
    data () {
      return {
        status: null
      };
    },
    computed: {
      token () {
        return this.$route.params.token;
      }
    },
    async mounted () {
      const api = new APIClient();

      const { status } = await api.getPasswordResetTokenStatus(this.token);
      this.status = status;
    }
  };
</script>

<style lang="scss">

</style>
