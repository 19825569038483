
const state = new Map();

/**
 * Stores a value into the stash
 * @param key {string}
 * @param value {any}
 */
function set (key, value) {
  state.set(key, value);
}

/**
 * Retrieves a value from the stash
 * @param key {string}
 * @return {any}
 */
function get (key) {
  const value = state.get(key);
  state.delete(key);
  return value;
}

export default {
  set,
  get
};
