<template>
  <AuthLayout :image="image">
    <h2 v-if="heading" class="heading-2 m-0 has-text-centered-large-down">
      {{ heading }}
    </h2>
    <GooSkeleton v-else width="100%" height="48" />

    <form v-if="step === 1" class="mt-5" @submit.prevent="checkRegistrationStatus">
      <GoogleSignInButton @authenticated="handleIntent">
        Sign up with Google
      </GoogleSignInButton>

      <div class="goo-divider has-text my-3">
        <span class="goo-divider-text">or</span>
      </div>

      <GooTextInput
        v-model="email"
        :error="errorForField('email')"
        class="mb-3"
        label="Enter Your Email Address"
        type="email"
        required
      />

      <p class="body-3">
        By sharing your email, you agree to our
        <a class="goo-link is-alternate" href="https://www.gooroo.com/terms-of-use" target="_blank">Terms of Use</a> and
        <a class="goo-link is-alternate" href="https://www.gooroo.com/privacy-policy" target="_blank">Privacy Policy.</a>
      </p>

      <GooSubmit class="goo-button is-primary is-full-width" :loading-text="false" :loading="submitting">
        Sign up with Email
      </GooSubmit>
    </form>
    <form v-else class="mt-3" @submit.prevent="signup">
      <p class="mb-3">
        <button class="signup-screen__back-button" type="reset" @click.prevent="goToFirstStep">
          <GooIcon name="arrow-left" /> <span>Back</span>
        </button>
      </p>

      <GooTextInput
        ref="firstNameField"
        v-model="firstName"
        name="first_name"
        :error="errorForField('first_name')"
        class="mb-3"
        label="First Name"
        required
      />

      <GooTextInput
        v-model="lastName"
        name="last_name"
        :error="errorForField('last_name')"
        class="mb-3"
        label="Last Name"
        required
      />

      <GooTextInput
        v-model="password"
        name="password"
        :error="errorForField('password')"
        class="mb-3"
        label="Create a Password"
        type="password"
        required
      />

      <GooSubmit class="goo-button is-primary is-full-width" :loading-text="false" :loading="submitting">
        Continue
      </GooSubmit>
    </form>

    <hr class="goo-divider mt-3 mb-2">

    <p class="has-text-centered">
      Already have an account? <RouterLink :to="loginURL" class="goo-link is-secondary">
        Log in
      </RouterLink>
    </p>
  </AuthLayout>
</template>

<script>
  import APIClient from "@/lib/api/APIClient";
  import AuthLayout from "@/components/Auth/AuthLayout";
  import GoogleSignInButton from "@/components/Auth/GoogleSignInButton";
  import FormErrorsMixin from "@/mixins/FormErrorsMixin";

  import ReadingImage from "@/assets/auth/signup-reading.jpg";
  import ScienceImage from "@/assets/auth/signup-science.jpg";
  import GooTextInput from "@/goo2/components/text-input/GooTextInput";

  import APIClientError from "@/lib/api/APIClientError";
  import SafeRedirectMixin from "@/mixins/SafeRedirectMixin";
  import { GET_GOOROO_PASS_PRICING } from "@/components/GoorooPass/queries";

  const api = new APIClient();

  export default {
    name: "SignupScreen",
    components: { GooTextInput, GoogleSignInButton, AuthLayout },
    mixins: [FormErrorsMixin, SafeRedirectMixin],
    props: {
      user: {
        type: Object,
        default: null
      }
    },
    data () {
      return {
        step: 1,
        pricing: null,
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        itzaUserId: null,
        itzaRefreshToken: null,
        submitting: false
      };
    },
    apollo: {
      pricing: {
        query: GET_GOOROO_PASS_PRICING,
        client: "courses",
        skip () {
          return this.intent !== "gooroo-pass";
        }
      }
    },
    computed: {
      image () {
        return this.step === 1 ? ScienceImage : ReadingImage;
      },
      heading () {
        if (this.intent === "gooroo-pass") {
          const pricing = this.pricing;

          if (pricing === null) {
            return null;
          }

          if (pricing.isEligibleForTrial) {
            return `Start your ${pricing.trialPeriodDays} day free trial`;
          }
        }

        return "Unlock your potential";
      },
      loginURL () {
        return {
          name: "auth.login",
          query: this.$route.query
        };
      }
    },
    mounted () {
      if (this.user !== null) {
        this.handleIntent();
      }
    },
    methods: {
      goToFirstStep () {
        this.step = 1;
      },
      async checkRegistrationStatus () {
        this.submitting = true;
        this.clearErrors();

        try {
          const { status } = await api.checkRegistrationStatus(this.email);

          switch (status) {
          case "REGISTERED":
            this.addError("email", "Email already exists");
            break;
          case "NOT_REGISTERED":
            this.step = 2;
            this.$nextTick(() => {
              if (this.$refs.firstNameField) {
                this.$refs.firstNameField.focus();
              }
            });
            break;
          }
        } catch (reason) {
          this.addError("email", reason.message);
        } finally {
          this.submitting = false;
        }
      },
      async signup () {
        this.submitting = true;
        this.clearErrors();

        try {
          await api.signup({
            email: this.email,
            firstName: this.firstName,
            lastName: this.lastName,
            password: this.password,
            itzaUserId: this.itzaUserId,
            itzaRefreshToken: this.itzaRefreshToken
          });

          this.handleIntent();
        } catch (reason) {
          if (reason instanceof APIClientError) {
            this.errors = reason.errors;
          }
        } finally {
          this.submitting = false;
        }
      }
    }
  };
</script>

<style lang="scss">
  .signup-screen {
    &__back-button {
      color: #969696;
      fill: #969696;
      display: flex;
      flex-direction: row;
      align-items: center;
      border: 0;
      background: none;
      font-weight: bold;
      cursor: pointer;
      position: relative;
      padding: 0;

      &:hover {
        color: #00bfc2;
        fill: #00bfc2;

        &:before {
          content: "";
          position: absolute;
          top: -0.25rem;
          left: -0.5rem;
          right: -0.5rem;
          bottom: -0.25rem;
          background: rgba(#000, 0.05);
          border-radius: 4px;
        }
      }

      .goo-icon {
        margin: 0 0.5rem 0 0;
      }
    }
  }
</style>
