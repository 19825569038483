<template>
  <div class="goo-input-group" :class="classes">
    <label v-if="label" class="goo-label" :for="elementId">
      {{ label }}
    </label>

    <!--suppress HtmlFormInputWithoutLabel -->
    <textarea v-if="multiline" :id="elementId" ref="field" class="goo-input" :value="value" v-bind="$attrs" @input="onInput" />
    <!--suppress HtmlFormInputWithoutLabel -->
    <input v-else :id="elementId" ref="field" class="goo-input" :value="value" v-bind="$attrs" @input="onInput">

    <div v-if="error" class="goo-input-hint">
      {{ error }}
    </div>
  </div>
</template>

<script>
  let nextID = 1;

  export default {
    name: "GooTextInput",
    inheritAttrs: false,
    props: {
      id: {
        type: String,
        default: null
      },
      label: {
        type: String,
        default: null
      },
      value: {
        type: String,
        default: ""
      },
      multiline: {
        type: Boolean,
        default: false
      },
      error: {
        type: String,
        default: null
      }
    },
    data () {
      return {
        instanceId: null
      };
    },
    computed: {
      elementId () {
        return this.id !== null ? this.id : `text-input-${this.instanceId}`;
      },
      classes () {
        return {
          "has-error": this.error !== null
        };
      }
    },
    mounted () {
      this.instanceId = nextID;
      nextID += 1;
    },
    methods: {
      onInput (event) {
        this.$emit("input", event.target.value);
      },
      focus () {
        this.$refs.field.focus();
      },
      blur () {
        this.$refs.field.blur();
      }
    }
  };
</script>

<style lang="scss">

</style>
