function formatTextualApproximateDuration (duration) {
  const sign = duration < 0 ? "-" : "";
  const hours = Math.floor(Math.abs(duration) / 3600);
  const minutes = Math.floor((Math.abs(duration) % 3600) / 60);

  const parts = [];

  if (hours > 0) {
    parts.push(`${hours}h`);
  }

  if (minutes > 0) {
    parts.push(`${minutes}m`);
  }

  return sign + parts.join(" ");
}

export const courseDuration = duration => formatTextualApproximateDuration(duration);
