<template>
  <div />
</template>

<script>
  import APIClient from "@/lib/api/APIClient";
  import SafeRedirectMixin from "@/mixins/SafeRedirectMixin";

  export default {
    name: "LogoutScreen",
    mixins: [SafeRedirectMixin],
    async mounted () {
      try {
        const api = new APIClient();
        await api.logout();
      } catch (error) {
        console.log(error);
      } finally {
        this.redirectToNextURL();
      }
    }
  };
</script>

<style lang="scss">

</style>
