
export default {
  data () {
    return {
      errors: []
    };
  },
  methods: {
    clearErrors () {
      this.errors = [];
    },
    addError (field, message) {
      this.errors.push({
        field,
        message
      });
    },
    errorForField (field) {
      const error = this.errors.find(error => error.field === field);
      return error ? error.message : null;
    }
  }
};
