import APIClientError from "@/lib/api/APIClientError";

export default class APIClient {
  /**
   * @param email {string}
   * @param password {string}
   * @return {Promise<object>}
   */
  async login (email, password) {
    return this._post("/auth/login", { email, password });
  }

  /**
   * @param googleIdToken {string}
   * @return {Promise<object>}
   */
  async loginWithGoogle (googleIdToken) {
    return this._post("/auth/login/google", {
      google_id_token: googleIdToken
    });
  }

  /**
   * @return {Promise<void>}
   */
  async logout () {
    return this._post("/auth/logout", {});
  }

  /**
   * @return {Promise<{status: string}>}
   */
  async checkRegistrationStatus (email) {
    return this._post("/auth/registration-status/check", { email });
  }

  /**
   * @param params {{email: string, firstName: string, lastName: string, password: string, itzaUserId: string, itzaRefreshToken: string}}
   * @return {Promise<unknown>}
   */
  async signup (params) {
    return this._post("/users", {
      email: params.email,
      first_name: params.firstName,
      last_name: params.lastName,
      password: params.password,
      itza_user_id: params.itzaUserId,
      itza_refresh_token: params.itzaRefreshToken,
      is_adult: params.isAdult
    });
  }

  /**
   * @param email {string}
   * @return {Promise<void, APIClientError>}
   */
  async createPasswordResetToken (email) {
    return this._post("/auth/password-reset-tokens", { email });
  }

  /**
   * @param refreshToken {string}
   * @return {Promise<unknown, APIClientError>}
   */
  async updateUser (params) {
    return this._put("/me", {
      itza_user_id: params.itzaUserId,
      itza_refresh_token: params.itzaRefreshToken
    });
  }

  /**
   * @param token {string}
   * @return {Promise<unknown, APIClientError>}
   */
  async getPasswordResetTokenStatus (token) {
    return this._get(`/auth/password-reset-tokens/${token}/status`);
  }

  /**
   * @param password {string}
   * @param token {string}
   * @return {Promise<void, APIClientError>}
   */
  async resetPassword (password, token) {
    return this._post("/auth/reset-password", { password, token });
  }

  _get (path) {
    return new Promise(this._makeRequestExecutor(path, {
      method: "GET",
      credentials: "include",
      headers: {
        "X-API-Key": process.env.VUE_APP_API_KEY,
        "Content-Type": "application/json"
      }
    }));
  }

  _post (path, data) {
    return new Promise(this._makeRequestExecutor(path, {
      method: "POST",
      credentials: "include",
      headers: {
        "X-API-Key": process.env.VUE_APP_API_KEY,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    }));
  }

  _put (path, data) {
    return new Promise(this._makeRequestExecutor(path, {
      method: "PUT",
      credentials: "include",
      headers: {
        "X-API-Key": process.env.VUE_APP_API_KEY,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    }));
  }

  _makeRequestExecutor (path, info) {
    return async function (resolve, reject) {
      const url = new URL(process.env.VUE_APP_API_BASE);
      url.pathname = path;

      try {
        const response = await fetch(url.toString(), info);

        const isError = (response.status >= 400 && response.status <= 499);

        if (isError) {
          const reason = await response.json();
          reject(new APIClientError(reason));
        } else {
          const contentType = response.headers.get("content-type");

          if (contentType && contentType.indexOf("application/json") !== -1) {
            const data = await response.json();
            resolve(data);
          } else {
            resolve();
          }
        }
      } catch (error) {
        reject(new APIClientError());
      }
    };
  }
}
